<template>
    <div class="center">
        <div class="m-4">
            <div class="clearfix">
                <img src="/media/images/logo.jpg" alt="" class="float-start">
                <div class="float-end">
                    <div class="text-danger fs-2">Quote</div>
                    <div>Quote time: {{datetime}} PST</div>
                </div>
            </div>
        </div>

        <spinner v-if="loading" />
        <div v-if="loading==false">
            <div class="m-4">
                <div>
                    <div><span class="fw-bold">Customer ID:</span> {{customer.custno}}</div>
                    <div><span class="fw-bold">Company:</span> {{customer.company}}</div>
                    <div><span class="fw-bold">Ma Labs Account Manager:</span> {{customer.sales}}</div>
                    <div><span class="fw-bold">Email Address:</span> {{customer.sales_email}}</div>
                    <div><span class="fw-bold">Contact Us:</span> Tel: 408-941-0808. Fax: 408-941-0909</div>
                </div>
            </div>    

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <td width="10%" class="fw-bold ps-4">Item No.</td>
                            <td width="32%" class="fw-bold">Product Description</td>
                            <td width="10%" class="fw-bold">Branch</td>
                            <td width="5%" align="right" class="fw-bold pe-2">Quantity</td>
                            <td width="12%" align="right" class="fw-bold pe-2">Instant Rebate</td>
                            <td width="10%" align="right" class="fw-bold pe-2">Unit Price</td>
                            <td width="10%" align="right" class="fw-bold pe-4">Subtotal</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items">
                            <td class="ps-4">{{item.itmno}}</td>
                            <td>{{item.description}}
                                <div v-if="item.system" class="mt-2">
                                    <div class="m-2"><i class="bi bi-wrench-adjustable"></i> <a class="pointer" @click="toggleConfigDetails(item.id)">Configuration Components</a></div>
                                    <div class="hide" :id="'config_details_'+item.id">
                                        <table class="table table-condensed table-striped">
                                            <tbody>
                                            <tr v-for="cp in item.system_details.item_list">
                                                <td width="20%" valign="top" class="pe-2">{{cp.itmno}}</td>
                                                <td width="80%">{{cp.desc}} ({{cp.qty}} pcs)  </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                            <td>{{branchName(item.branch)}}</td>
                            <td align="right" class="pe-2">{{item.quantity}}</td>
                            <td align="right" class="pe-2">
                                <span v-if="item.instant_rebate_total">{{formatPrice(item.instant_rebate_total)}}</span>
                                <span v-else>N/A</span>
                            </td>
                            <td align="right" class="pe-2">{{formatPrice(item.unit_price)}}</td>
                            <td align="right" class="pe-4">{{formatPrice(item.sub_total)}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="mb-5">
                    <div class="clearfix">
                        <div class="col-md-2 float-end text-end pe-4">{{formatPrice(total_price)}}</div>
                        <div class="col-md-8 float-end fw-bold text-end">Total Price:</div>
                    </div>
                    <div class="clearfix">
                        <div class="col-md-2 float-end text-end pe-4">
                            <span v-if="total_rebate">{{formatPrice(total_rebate)}}</span>
                            <span v-else>N/A</span>
                        </div>
                        <div class="col-md-8 float-end fw-bold text-end">Total Instant Rebate:</div>
                    </div>
                    <div class="clearfix">
                        <div class="col-md-2 float-end text-end pe-4">{{formatPrice(grand_total_price)}}</div>
                        <div class="col-md-8 float-end fw-bold text-end">Grand Total:</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-4">
            <p><span class="fw-bold">Note:</span> If quantity is not correct, please close the window, go back to the shopping cart and refresh it by clicking the "Update all" button.</p>
            <p>
                <div><u>Terms & Conditions</u></div>
                <div>The Terms and Conditions of Sale at www.malabs.com/terms-conditions apply to all transactions.</div>
            </p>
            <p>
                <div><u>Price and Availability</u></div>
                <div>Please check with your account manager for final pricing and product availability (subject to change without prior notice). Unless otherwise indicated, all price quotes are based on a cash discount offered to all buyers for payment by cash or check as permitted by law. Some Brands may not be eligible for sale outside the U.S. Please review with your account manager. All logistics charges, fees, costs, and taxes are subject to change based on final destination.</div>
            </p>
            <p>
                <div><u>Destination Control Statement</u></div>
                <div>Diversion contrary to US export laws of goods or services purchased from Ma Labs is strictly prohibited.</div>
            </p>

            <p class="mt-5">Copyright © {{year}} Ma Laboratories, Inc. All Rights Reserved.</p>
        </div>
        
    </div>
</template>

<script>
import Spinner from "../../components/Spinner.vue";
import { useStore } from 'vuex'
import { ref } from 'vue'
import { getAuthAPI } from '../../utils/axios-api'
import { formatPrice, branchName } from '../../utils/common'

export default {
  name: 'Quote',
  components: { Spinner },
  setup(props, context) {
    const loading = ref(true)
    const error = ref(null)
    const store = useStore()
    const customer = ref(false)
    const is_customer = ref(store.getters.isCustomer)
    const items = ref(null)
    const total_price = ref(0.0)
    const total_rebate = ref(0.0)
    const grand_total_price = ref(0.0)
    const datetime = ref(null)
    const year = ref(null)

    //get current date time
    var currentdate = new Date().toLocaleString('en-US', { hour12: false });
    datetime.value = currentdate
    var currentdate = new Date()
    year.value = currentdate.getFullYear()

    if (is_customer.value == true) {
        //get account info
        getAuthAPI.get('/account/my-account/')
        .then(response => {
            customer.value = response.data.results
        })
        .catch(err => {
            error.value = err.message
        })

        //get po cart info
        getAuthAPI.get("/order/get-cart/")
        .then(response => {
            loading.value = false
            items.value = response.data
            for (var i = 0; i < items.value.length; i++){
                // total_price.value = total_price.value + items.value[i]["sub_total"]
                total_price.value = total_price.value + items.value[i]["total_price"]
                total_rebate.value = total_rebate.value + items.value[i]["instant_rebate_total"]
            }

            grand_total_price.value = total_price.value + total_rebate.value

        })
        .catch(err => {
            loading.value = false
            error.value = err.response.data
        })
    
    }

    return {
        loading,
        error,
        customer,
        is_customer,
        datetime,
        year,
        items,
        total_price,
        total_rebate,
        grand_total_price,
        formatPrice,
        branchName
    }
 }
}
</script>

<style scoped>
.center {
    margin: auto;
    width: 70%;
    border: 1px solid #ccc;
    padding: 0px;
}
</style>